<template>

    <div class="store_container">
        <ul v-for="(val, index) in imgArr" :key="index">
            <div class="image-section">
                <picture>
                    <source :srcset=val.mobile media="(max-width: 768px)">
                    <img class="view-image" :src="val.pc">
                </picture>
            </div>
            <div v-show="val.isButton" class="button-section" >
                <button class="section-button" @click="goLogin"><p>{{ button_text }}</p></button>
            </div>
        </ul>
    </div>

</template>

<script>
export default {
  name: 'NewStoreLanding',

  data () {
    return {
      button_text: '무료 체험하기',
      imgArr: [
        {
          mobile: require("../../../public/media/img/landing/store/mo_store_1.png"),
          pc: require("../../../public/media/img/landing/store/pc_store_1.png"),
          isButton: true

        },
        {
          mobile: require("../../../public/media/img/landing/store/mo_store_2.png"),
          pc: require("../../../public/media/img/landing/store/pc_store_2.png"),
          isButton: true

        },
        {
          mobile: require("../../../public/media/img/landing/store/mo_store_3.png"),
          pc: require("../../../public/media/img/landing/store/pc_store_3.png"),
          isButton: false

        }
      ]
    };
  },
  mounted () {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#ffffff';
    body.style.height = 'auto';
  },

  destroyed () {
    const body = document.querySelector('body');
    body.style.backgroundColor = '';
    body.style.height = '';
  },

  methods: {
    goLogin () {
      this.$router.push({ name: 'Login' }).catch(() => {});
    }
  }
};

</script>

<style scoped>

  .store_container {
    display:block;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    padding-bottom: 67px;
  }

  .store_container .image-section{
    max-width: 800px;
    overflow:hidden;
    margin:0 auto;
  }

  .image-section .view-image {
    width:100%;
    height:100%;
    object-fit:cover;
  }
  .store_container .button-section{
    max-width: 800px;
    margin: 20px 0 20px 0;
    text-align: center;
    background-color: #ffffff;
  }

  button {
    display :inline-block;
    margin: 0 auto;
    border-radius: 20px;
    width: 623px;
    height: 89px;
    color: #ffffff;
    background-color: #FF2D55;
  }

  button > p {
    height:60px;
    font-size: 50px;
    font-weight: 700;
    line-height: 59.67px;
    text-align: center;
  }

  @media all and (max-width: 768px) {
    .store_container .button-section{
      margin: 0px 0 20px 0;
      width: 100%;
    }
    button {
      width: 285px;
      height: 37px;
      font-size: 16px;
      border-radius: 10px;
    }
    button > p{
      height:24px;
      font-size: 20px;
      font-weight: 700;
      line-height: 23.87px;

    }
  }

</style>
